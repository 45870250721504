import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../../components/seo"
import Header from "../../components/buckmemo/header"
import PageFrame from "../../components/page-frame"
import Part from "../../components/buckmemo/about-part"
import Footer from "../../components/footer"

export default ({ location }) => {
	const intl = useIntl()
	return (<>
		<SEO
			title={intl.formatMessage({ id: "buckmemo.about" })}
			pathname={location.pathname}
			siteName="buckmemo.name" />
		<Header aboutLinked={false} />
		<PageFrame>
			<Part isBox={false} title="about" desc="buckmemo.about_desc" />
			<Part name="why_develop_it" />
			<Part name="what_target" />
			<Part name="why_is_bookkeeping_necessary" />
			<Part name="chance_to_succeed" />
		</PageFrame>
		<Footer showTermsOfUse={true} showPrivacyPolicy={true} part="buckmemo" />
	</>)
}