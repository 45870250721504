import React from "react"
import styles from "./header.module.scss"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import PageFrame from "../page-frame"

export default ({
	homeLinked = true,
	aboutLinked = true,
	aboutLabel = "about"
}) =>
<header className={styles.header}>
	<PageFrame>
		<div className={styles.menu}>
			{homeLinked?
				<Link to="/buckmemo">
					<FormattedMessage id="buckmemo.name" />
				</Link>
				:
				<div>
					<FormattedMessage id="buckmemo.name" />
				</div>
			}
			{aboutLinked?
				<Link to="/buckmemo/about" className={styles.about}>
					<FormattedMessage id="about" />
				</Link>
				:
				<div className={styles.about}>
					<FormattedMessage id={aboutLabel} />
				</div>
			}
		</div>
	</PageFrame>
</header>