import React from "react"
import styles from "./about-part.module.scss"
import { FormattedMessage } from "gatsby-plugin-intl"

export default ({ 
	isBox = true,
	name = "",
	title = "",
	desc = ""
}) =>
<div className={isBox?styles.box:styles.topBox}>
	<div className={isBox?styles.subHeader:styles.pageTitle}>
		<FormattedMessage id={isBox?`${name}.title`:title} />
	</div>
	<div className={styles.desc}>
		<FormattedMessage id={isBox?`${name}.desc`:desc}
			values={{
				p: (...chunks) => <p>{chunks}</p>
			}} />
	</div>
</div>